import { Component, Input } from '@angular/core';
import {Location} from '@angular/common';
import { Observable } from 'rxjs';

import { Breadcrumb } from '../../model/breadcrumb.model';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Router } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class BreadCrumbComponent {
  staticText: any = (textConfiguration as any).default;
url:any;
  breadcrumbs$: Observable<Breadcrumb[]>;
   @Input() showColorBlue:any;
   constructor(private breadcrumbService: BreadcrumbService,private _location:Location,private router: Router) {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;

    this.url = this.router.url
  }
  goBack(){
    this._location.back()
  }

}

