import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'treefilter',
  pure: false
})
export class TreefilterPipe implements PipeTransform {

  transform(items: any[], filterListKey: string, filterListKeyValue: boolean) {
    let filterTData: any[] = [], filterFData: any[] = [];
    items.filter((filterRes: any) => {
      if (filterRes[filterListKey] == filterListKeyValue) {
        filterTData.push(filterRes);
      }      
    })
    return filterTData || filterFData;
  }
}
