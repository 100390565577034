import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceCharacter'
})
export class ReplaceCharacterPipe implements PipeTransform {

  transform(stringVal: string, fromChar:string,tochar:string): string {
    if(stringVal){
      stringVal = stringVal.replace(/_+/g," ")
    }
    return stringVal;
  }

}
