import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArrayValues'
})
export class JoinArrayValuesPipe implements PipeTransform {

  transform(arr:any=[],key:string) {
    if(arr && arr.length){
      arr = arr.map((e:any)=> e[key]);
      arr = arr.join("/");
    }
    return arr;
  }

}
