import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDataString'
})
export class ConvertDataStringPipe implements PipeTransform {

  transform(arr: any[], keys: any[],startCount:number=1): unknown {
    var sdata = "";
    for (let i in arr) {
      if (Number(i) >= startCount) {
        if(keys.length > 0){
          for (let key in keys) {
            sdata += arr[i][keys[key]] + " ";
          }
        }else{
          sdata += arr[i] + " ";
        }
        if (Number(i) != arr.length - 1)
          sdata += ",\n ";
      }
    }
    sdata = sdata
    console.log(sdata);
    
    return sdata;
  }

}
