<div class="modal-header">
    <h4 class="modal-title">Hi {{name!}}</h4>
    <!-- <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button> -->
</div>
    <div class="comingSoon">
        <div>
            {{message}}
        </div>
    </div> 
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Got It</button>
</div>

