import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comparePortFolio'
})
export class FilterPortfoliosPipe implements PipeTransform {

  transform(actualArray: any, compareArray: any): any {
    compareArray = compareArray?.map((comparePortFolio: any) => {
      let found = actualArray?.find((findActualPortfoilios: any) => findActualPortfoilios?.id === comparePortFolio?.id)
      if (found) {
        comparePortFolio['disable_state'] = false;
      } else {
        comparePortFolio['disable_state'] = true;
      }
      return comparePortFolio;
    });
    return compareArray;
  }
}
