import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class DeletePopupComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popupData: any,
  ) {
    dialogRef.disableClose = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
  }

  ngOnInit(): void { }

  closeDialog() {
    this.dialogRef.close(null);
  }

  delete() {
    this.dialogRef.close(true)
  }

}
